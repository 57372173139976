import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./assets/css/fa-all.min.css";
import './assets/scss/App.scss';
import Contact from './Contact';
import Footer from './Footer';
import Header from './Header';
import Carousel from "./HomeCarousel";
import headshot from './assets/images/ScottHeadshot.png';
import SplitText from "./components/SplitText";
import React, { } from "react";

function App() {
    return (
        <div className="outer_wrapper">
            <div className="container">
                <div className=""></div>
                <Header />
                <section className="about">
                    <div className="wrapper">
                        <div className="side_1">
                            <div className="content">
                                <h2 className="text_gradient fancy-header"><SplitText copy="About Scott" role="heading" /></h2>
                                <h3 className="fancy-header"><SplitText copy="Vegas Based Digital & UI/UX Designer" role="heading" /></h3>
                                <p>
                                    With over 18 years of experience, Scott excels with interdisciplinary design (particularly with UI/UX design), digital strategy, 
                                    web development, and project management. He adeptly tackles complex design challenges with meticulous planning, creating
                                    scalable mobile applications and websites. Scott's exceptional design style and extensive knowledge of UI/UX techniques
                                    and technologies have earned him numerous design awards and accolades, including platinum and gold marketing communications awards.
                                </p>
                                <p className="btn">
                                    <a href="https://www.linkedin.com/in/slpatterson/" title="Visit Scott's LinkedIn Profile" target="_blank" rel="noreferrer">LinkedIn <i className="fab fa-linkedin"></i></a>
                                </p>
                            </div>
                        </div>
                        <div className="side_2">
                            <div className="content">
                                <img src={headshot} alt="Scott Patterson Headshot" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="portfolio">
                    <div className="wrapper">
                        <div className="side_1">
                            <div className="content">
                                <h2 className="text_gradient fancy-header"><SplitText copy="Scott's Portfolio" role="heading" /></h2>
                                <p>
                                    Scott's expertise encompasses UI/UX design, mobile application development, accessibility, content management
                                    systems, project management, and establishment of functional guidelines and brand standards. Precision in crafting interfaces
                                    for mobile devices ensures an exceptional end-user experience. From initial concepts to detailed mockups, Scott's approach
                                    integrates thorough research and exploration to deliver optimal user-centric solutions. Below is a curated selection from
                                    Scott's portfolio.
                                </p>
                            </div>
                        </div>
                        <div className="side_2">
                            <div className="content">
                                <Carousel />
                            </div>
                        </div>
                    </div>
                </section>
                <Contact />
                <Footer />
            </div>
        </div>
    );
}

export default App;