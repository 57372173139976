import React from "react";
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import Reaptcha from "reaptcha";
import {useState} from 'react';
import 'react-toastify/dist/ReactToastify.min.css';

const ContactForm = () => {

  const [isActive1, setActive1] = useState("false");
  const ToggleClass1 = () => {
    setActive1(!isActive1); 
   };

   const [isActive2, setActive2] = useState("false");
   const ToggleClass2 = () => {
     setActive2(!isActive2); 
    };

    const [isActive3, setActive3] = useState("false");
    const ToggleClass3 = () => {
      setActive3(!isActive3); 
     };
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  
  const [verified, setVerified] = React.useState(false);
  const captcha = React.createRef();
  
  const onVerify = e => {
    setVerified(true);
    ToggleClass1();
    ToggleClass2();
  };
  
  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast.success('Success! Thank you for you contacting Scott Patterson.', {
      position: 'bottom-center',
      //autoClose: 5000,
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
    ToggleClass3();
  };
  
  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, email, subject, message } = data;
    try {
      const templateParams = {
        name,
        email,
        subject,
        message
      };
      
      await emailjs.send(
        //process.env.REACT_APP_SERVICE_ID,
        //process.env.REACT_APP_TEMPLATE_ID,
        //templateParams,
        //process.env.REACT_APP_USER_ID
        'service_32sbcl5',
        'template_kace5hd',
        templateParams,
        'Gkn41yGsFbCgSN0VK'
      );

      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className={isActive3 ? "contact_form_wrapper active" : "contact_form_wrapper inactive"}>
          <div className="contact_row">
            <div>
              <input
                type='text'
                name='name'
                {...register('name', {
                  required: { value: true, message: 'Name is required.' },
                  maxLength: {
                    value: 30,
                    message: 'Please use 30 characters or less.'
                  }
                })}
                className='form-control formInput'
                placeholder='Name'
              />
              {errors.name && <span className='errorMessage'><i className="fas fa-exclamation-triangle fa-sm"></i> {errors.name.message}</span>}
            </div>
            <div>
              <input
                placeholder="Email"
                {...register('email', {
                  required: 'Email is required.',
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email.',
                  },
                })}
                type="email"
                required
                className="input"
              />
              {errors.email && (<span className='errorMessage'><i className="fas fa-exclamation-triangle fa-sm"></i> {errors.email.message}</span>)}
            </div>
            <div>
              <input
                type='text'
                name='subject'
                {...register('subject', {
                  required: { value: true, message: 'Subject is required.' },
                  maxLength: {
                    value: 75,
                    message: 'Subject cannot exceed 75 characters.'
                  }
                })}
                className='form-control formInput'
                placeholder='Subject'
              />
              {errors.subject && (
                <span className='errorMessage'><i className="fas fa-exclamation-triangle fa-sm"></i> {errors.subject.message}</span>
              )}
            </div>
          </div>
          <textarea
            rows={3}
            name='message'
            {...register('message', {
              required: true
            })}
            className='form-control formInput'
            placeholder='Message'
          ></textarea>
          {errors.message && <span className='errorMessage'><i className="fas fa-exclamation-triangle fa-sm"></i> Message is required.</span>}
          <Reaptcha
            sitekey="6LcFUvwUAAAAAPDLJREDxpQ46QHSUfwD64mXMaZY"
            onVerify={onVerify}
            ref={captcha}
            size="invisible"
          />
          <div className={isActive1 ? "btn active" : "btn inactive"}>
            <a onClick={() => {captcha.current.execute();}}>
              {isActive1 ? "Veryify You're a Human" : "You've Been Verified"} <i className="fas fa-user"></i>
            </a>
          </div>

          <button className={isActive2 ? "submit-btn inactive" : "submit-btn active"} type="submit" disabled={!verified}>
            Send <i className="fas fa-paper-plane"></i>
          </button>
        </div>
        <ToastContainer />
      </form>
    </>
  );
};

export default ContactForm;
