import React, { useState, useEffect, PropTypes } from 'react';
import anime from 'animejs/lib/anime.es.js';

const props = {}

function SplitText(props) {
    const animationRef = React.useRef(null);
    React.useEffect(() => {
        var textWrapper = document.querySelector(".fancy-header");
        textWrapper.innerHTML = textWrapper.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
        anime({
            targets: '.fancy-header .letter',
            translateX: [140, 0],
            translateZ: 0,
            opacity: [0, 1],
            easing: "easeOutExpo",
            duration: 1200,
            delay: (el, i) => 500 + 30 * i
        });
    }, []);

    return (
        
        <span aria-label={props.copy} role={props.role}>
            {props.copy.split("").map(function (char, index) {
                let style = { "animationDelay": (0.5 + index / 10) + "s" }
                return <span
                    aria-hidden="true"
                    className="letter"
                    key={index}
                    style={style}>
                    {char}
                </span>;
            })}
        </span>
    );
}

export default SplitText