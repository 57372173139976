import React from "react";
import { Carousel } from "react-responsive-carousel";
import img_001 from './assets/images/2024_WebPortfolio_001.jpg';
import img_002 from './assets/images/2024_WebPortfolio_002.jpg';
import img_003 from './assets/images/2024_WebPortfolio_003.jpg';
import img_004 from './assets/images/2024_WebPortfolio_004.jpg';
import img_005 from './assets/images/2024_WebPortfolio_005.jpg';
import img_006 from './assets/images/2024_WebPortfolio_006.jpg';
import img_007 from './assets/images/2024_WebPortfolio_007.jpg';
import img_008 from './assets/images/2024_WebPortfolio_008.jpg';
import img_009 from './assets/images/2024_WebPortfolio_009.jpg';
import img_010 from './assets/images/2024_WebPortfolio_010.jpg';
import img_011 from './assets/images/2024_WebPortfolio_011.jpg';
import img_012 from './assets/images/2024_WebPortfolio_012.jpg';

export default () => (
    <Carousel autoPlay showThumbs={false} infiniteLoop={true} showStatus={false} showIndicators={false}>
        <div className="item">
            <img src={img_001} width="100%" loading="lazy" />
            <div className="citation">
                <h4 className="caption">Konetic UI/UX &amp; Development</h4>
                <cite>Produced at Konami | &copy; Konami Gaming, Inc. All rights reserved.</cite>
            </div>
        </div>
        <div className="item">
            <img src={img_002} width="100%" loading="lazy" />
            <div className="citation">
                <h4 className="caption">IGT Game Page UI/UX</h4>
                <cite>Produced at IGT | &copy; IGT. All rights reserved.</cite>
            </div>
        </div>
        <div className="item">
            <img src={img_012} width="100%" loading="lazy" />
            <div className="citation">
                <h4 className="caption">Konetic UI/UX &amp; Development</h4>
                <cite>Produced at Konami | &copy; Konami Gaming, Inc. All rights reserved.</cite>
            </div>
        </div>
        <div className="item">
            <img src={img_003} width="100%" loading="lazy" />
            <div className="citation">
                <h4 className="caption">IGT Search UI/UX &amp; Development</h4>
                <cite>Produced at IGT | &copy; IGT. All rights reserved.</cite>
            </div>
        </div>
        <div className="item">
            <img src={img_004} width="100%" loading="lazy" />
            <div className="citation">
                <h4 className="caption">IGT My Account UI/UX</h4>
                <cite>Produced at IGT | &copy; IGT. All rights reserved.</cite>
            </div>
        </div>
        <div className="item">
            <img src={img_005} width="100%" loading="lazy" />
            <div className="citation">
                <h4 className="caption">SCA Promotions Hero UI/UX &amp; Development</h4>
                <cite>Freelance Work</cite>
            </div>
        </div>
        <div className="item">
            <img src={img_006} width="100%" loading="lazy" />
            <div className="citation">
                <h4 className="caption">Brand Microsite</h4>
                <cite>Produced at IGT | &copy; IGT. All rights reserved.</cite>
            </div>
        </div>
        <div className="item">
            <img src={img_007} width="100%" loading="lazy" />
            <div className="citation">
                <h4 className="caption">Healthy with Nedi UI/UX &amp; Development</h4>
                <cite>Freelance Work</cite>
            </div>
        </div>
        <div className="item">
            <img src={img_008} width="100%" loading="lazy" />
            <div className="citation">
                <h4 className="caption">AAF Website Design &amp; Development</h4>
                <cite>AAF Board Work</cite>
            </div>
        </div>
        <div className="item">
            <img src={img_009} width="100%" loading="lazy" />
            <div className="citation">
                <h4 className="caption">XRT Consultants UI/UX &amp; Development</h4>
                <cite>Freelance Work</cite>
            </div>
        </div>
        <div className="item">
            <img src={img_010} width="100%" loading="lazy" />
            <div className="citation">
                <h4 className="caption">IGT Jackpots</h4>
                <cite>Produced at IGT | &copy; IGT. All rights reserved.</cite> 
            </div>
        </div>
        <div className="item">
            <img src={img_011} width="100%" loading="lazy" />
            <div className="citation">
                <h4 className="caption">IGT Email Design</h4>
                <cite>Produced at IGT | &copy; IGT. All rights reserved.</cite>
            </div>
        </div>
    </Carousel>
);