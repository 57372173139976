import * as React from "react"
import logo from './assets/images/SP_Logo_2.0.0.svg';
import placeholderVideo from './assets/images/header_vid_021420241757.jpg'
import CurrentTime from './Time';
import Greeting from './Greeting';

function Header() {
    return (
        <>
            <header>
                <div className="video_placeholder">
                    <img src={placeholderVideo} alt="Las Vegas Based UI & UX Designer/Developer" />
                </div>
                <iframe src="https://player.vimeo.com/video/913540175?background=1" frameBorder="0" allow="autoplay" title="Scott Patterson - Las Vegas Based UI & UX Designer/Developer"></iframe>
                <p className="header_time"><Greeting /> <CurrentTime /></p>
                <a href="/" title="Scott's Homepage">
                    <h1 className="logo">
                        <span>Scott Patterson &ndash; A Las Vegas Based UI & UX Designer/Developer</span>
                        <img src={logo} alt="Scott Patterson" />
                    </h1>
                </a>
            </header>
        </>
    )
}

export default Header