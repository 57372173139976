import * as React from "react"
import SplitText from "./components/SplitText";
import ContactForm from "./ContactForm"

function Contact() {
    return (
        <>
            <section className="contact">
                <div className="wrapper">
                    <div className="content">
                        <h2 className="fancy-header"><SplitText copy="Contact Scott" role="heading" /></h2>
                        <p>Whatever the inquiry might be, please reach out. Utilize the form below, and contact will be made as promptly as possible. Additionally, connect through the social media channels provided to learn more!</p>
                        <ContactForm />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact