import React, {Component} from "react";

class Greeting extends React.Component {
    state = {
        hour: null,
        //username: 'Alyssa'
    }

    componentDidMount() {
        this.getHour()
    }

    getHour = () => {
        const date = new Date();
        const hour = date.getHours()
        this.setState({
            hour
        });
    }

    render() {
        const { hour } = this.state;
        return (
        <span>{hour<12 && "Morning! It's" || hour<18 && "Afternoon! It's" || "Evening! It's"}</span>
        );
    }
}

export default Greeting