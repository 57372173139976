import React from "react";
import logo from './assets/images/SP_Logo_2.0.0.svg';

export const Footer = () => {
    let getYear = () => {
        let currentYear = new Date().getFullYear();
        return currentYear;
    };
    return (
        <>
            <footer>
                <div className="content">
                    <img src={logo} alt="Scott Patterson" />
                    <p className="copyright">&copy; Copyright {getYear()} &ndash; Scott Patterson. Some rights reserved.</p>
                    <p className="handcrafted">This handcrafted website was proudly designed and developed with &nbsp;<i className="fa fa-heart"></i>&nbsp; by Scott Patterson in Las Vegas, Nevada. Made With React <i className="fab fa-react"></i></p>
                    <p className="googlerecaptcha">
                        Protected by reCAPTCHA and the Google <a onClick={()=> window.open("https://policies.google.com/privacy", "_blank")} href="!#" rel="noreferrer noopener">Privacy Policy</a><br /> and <a onClick={()=> window.open("https://policies.google.com/terms", "_blank")} href="!#" rel="noreferrer noopener">Terms of Service</a> apply.
                    </p>
                    <ul className="social_icons">
                        <li><a href="http://www.linkedin.com/in/slpatterson" title="LinkedIn" target="_blank" rel="noreferrer"><i className="fab fa-linkedin-in"></i></a></li>
                        <li><a href="https://www.facebook.com/oscotto" title="Facebook" target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a></li>
                        <li><a href="https://instagram.com/namsted" title="Instagram" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </footer>
        </>
    )
}

export default Footer